<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="" cols="12">
        <h1 class="display-1 font-weight-bold mb-3">
          {{title}}
        </h1>
      </v-col>

    <!--   <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">Fahrzeug-Typ wählen:</h2>
         <v-row justify="center">
          <v-sheet class="mx-auto" max-width="1200" min-width="400">
            <v-slide-group v-model="value_type" class="pa-4" show-arrows>
              <v-slide-item
                v-for="n in vehicle_details"
                :key="n.id"
                v-slot="{ active, toggle }"
                :value="n"
              >
                <v-card
                  :outlined= "(active) ? true : false"
                  class="pa-3"
                  elevation="0"
                  @click="toggle()"
                >
                  <div align="center">
                    <v-img
                      width="100"
                      class="d-block mb-2"
                      :src="base_url + n.icon"
                    />
                    <label>{{ n.name }}</label>
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-row> 
      </v-col>-->
      <v-col class="mb-5" cols="12">
        <v-row justify="center mb-5">
        <!-- <v-radio-group label="" v-model="active" mandatory row>
      <v-radio  name="active" label="Vor Ort" value="Vor Ort" :disabled="vor_ort_disabled"></v-radio>
      <span style="padding-right:10px">oder </span>
      <v-radio  name="active" label="Online" value="Online" :disabled="online_disabled"></v-radio>
    </v-radio-group> -->

<!-- :outlined= "(active) ? true : false" -->
          <v-sheet class="mx-auto book-type" max-width="1200">
            <!-- <v-slide-group v-model="value_brand" class="pa-4" show-arrows>
              <v-slide-item
                key="1"
                v-slot="{ toggle }"
                value="Vor Ort"
              >
                <v-card
                  outlined= "true"
                  class="pa-2"
                  width="120"
                  elevation="0"
                  @click="toggle()"
                >
                  <div class="" align="center" justify="center">
                    <label>Vor Ort</label>
                  </div>
                </v-card>
              </v-slide-item>
              <span class="pa-2 ma-0">oder</span>
              <v-slide-item
                key="2"
                v-slot="{ toggle }"
                value="Online"
              >

                <v-card
                  outlined= "true"
                  class="pa-2"
                  width="120"
                  elevation="0"
                  @click="toggle()"
                >
                  <div class="" align="center" justify="center">
                    <label>Online</label>
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group> -->
              <!-- <div> {{this.booking_type}}</div> -->
                <v-chip-group
                active-class="primary--text"
                v-model="active"
                column
              >
                <v-chip
                  large
                  class="ma-2"
                  :value="n.name"
                  filter
                  v-for="n in booking_type"
                  :key="n.name"
                >
                  {{n.name}}
                </v-chip>
                <!-- <span class="pt-5 ma-0">oder</span>
                <v-chip
                  large
                  class="ma-2"
                  value="Online"
                  filter
                  key="2"
                  :disabled="online_disabled"
                >
                  Online
                </v-chip> -->
              </v-chip-group>
          </v-sheet>

        </v-row>
        <h2 class="headline font-weight-bold mb-3">{{brand_select_text}}</h2>
        <v-row justify="center">
          <v-sheet class="mx-auto" max-width="1200" min-width="400">
            <v-slide-group v-model="value_brand" class="pa-4" show-arrows>
              <v-slide-item
                v-for="n in brand_details"
                :key="n.id"
                v-slot="{ active, toggle }"
                :value="n"
              >

                <v-card
                  :outlined= "(active) ? true : false"
                  class="pa-2"
                  width="120"
                  :hidden="check_brand_enabled(n)"
                  elevation="0"
                  @click="toggle()"
                >
                  <div class="" align="center" justify="center">
                    <v-img class="d-block" :src="base_url + n.logo" />
                    <label>{{ n.name }}</label>
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-row>
   
      <v-row>
        <v-sheet class="mx-auto" max-width="1200" min-width="400">
          <!-- <v-slide-group v-model="value_locations" class="pa-4" show-arrows> -->
                <!-- <v-chip-group
                active-class="primary--text"
                v-if="locations != null"
                v-model="value_locations"
                column
              > -->
                <!-- active-class="primary--text" -->
                <v-slide-group
                active-class="primary white--text"
                v-if="locations != null"
                v-model="value_locations"
                class="pa-4" show-arrows
              >
              <v-slide-item
                v-for="n in locations"
                :key="n.location"
                :value="n.location"
                 v-slot:default="{ active, toggle }"
              >
                <!-- <v-chip
                  x-large chip
                  class="ma-2 pa-5"
                  :value="n.location"
                  label
                  :key="n.location"
                > -->

                <v-card
                  :color="active ? undefined : 'grey lighten-1'"
                  class="pa-2 ma-5"
                  elevation="0"
                  @click="toggle()"
                >
                <!-- <div class="" align="center" justify="center"> -->
                <div v-html="n.lable" />
                </v-card>
                <!-- </div> -->
                
                <!-- </v-chip> -->
                </v-slide-item>
                <!-- <span class="pt-5 ma-0">oder</span>
                <v-chip
                  large
                  class="ma-2"
                  value="Online"
                  filter
                  key="2"
                  :disabled="online_disabled"
                >
                  Online
                </v-chip> -->
              </v-slide-group>
            </v-sheet>
      </v-row>
         </v-col>
         <!-- <v-row align="center">
           <v-col class="d-flex" cols="12" sm="4">
           </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              :items="locations"
              v-model="value_locations"
              label="Standort wählen"
              outlined
              class="text-uppercase"
            >
              <template slot="no-data">
                <v-list-item>keine Einträge</v-list-item>
              </template></v-select
            >
          </v-col>
           <v-col class="d-flex" cols="12" sm="4">
           </v-col>
        </v-row> -->
<!-- 
      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">
          Wählen Sie ein Fahrzeug für Ihre Probefahrt:
        </h2>

        <v-row justify="center">
          <v-sheet class="mx-auto" max-width="1200" min-width="100%">
            <v-item-group v-model="value_vehicle" active-class="">
              <v-card elevation="5" style="height: 550px; overflow-y: scroll">
                <v-container>
                  <v-row>
                    <v-col
                      v-for="n in vehicleResult"
                      :key="n.id"
                      cols="12"
                      md="3"
                      sm="6"
                      xs="12"
                      class="pb-0"
                    >
                      <v-item v-slot="{ active, toggle }" :value="n">
                        <v-card
                          :outlined= "(active) ? true : false"
                          class="pa-2 justify-center text-center"
                          width="220"
                          elevation="0"
                          @click="toggle()"
                          style="margin: auto"
                        >
                          <div class="" align="center" justify="center">
                            <v-img
                              class="d-block"
                              :src="base_url + n.car_photo"
                            />
                            <p class="text-h6 ma-0 text-uppercase">
                              {{ n.brand + " " + n.model }}
                            </p>
                            <label class="text-caption">{{
                              n.model_and_description
                            }}</label>
                            <label class="text-caption">{{
                              n.ps +
                              " | " +
                              n.type_of_fuel +
                              " | " +
                              n.transmission +
                              " | " +
                              n.color
                            }}</label>
                            <br>
                            <label class="location-label" for="">{{n.location_frontend_label}}</label>
                            
                          </div>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-item-group>
          </v-sheet>
        </v-row>
      </v-col> -->

      <v-col class="mb-15" cols="12">
        <h2 class="headline font-weight-bold mb-10">
          <!-- Wann möchten Sie uns besuchen? -->
          {{appointment_select_text}}
        </h2>

        <v-row justify="center">
          <v-col class="custom-datepicker">
            <v-date-picker
              style="font-size: 16px"
              :min="current_date"
              v-model="value_datePicker"
              :first-day-of-week="1"
              locale="de-DE"
            ></v-date-picker>
          </v-col>
          <v-col>
            <v-row justify="start" align="center">
              <v-chip-group
                active-class="primary--text"
                v-model="value_selected_slot"
                column
              >
                <v-chip
                  large
                  class="ma-2"
                  :value="n"
                  filter
                  v-for="n in items_available_slots"
                  :key="n.title"
                  :disabled="check_slot_booked(n)"
                >
                  {{ n.title }}
                </v-chip>
              </v-chip-group>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "BookTestDrive",
  methods: {
    ...mapActions([
      "get_car_type_model_details",
      "get_details",
      "get_car_details",
      "get_available_slot",
      "get_location_from_brand",
      "get_brands_from_location",
      "get_available_slot_consultation",
      "get_text_setting"
    ]),
    prepare_car_type_model_details: function () {
      this.get_car_type_model_details().then((response) => {
        // this.car_type_model_details = response;
        // this.vehicle_details = response.vehicle_details;
        this.brand_details = response.brand_details;
        // this.vehicle_type_filter = response.vehicle_type_filter;
        // this.brand_details_filter = response.brand_details_filter;
        // this.enabled_brand_details = response.brand_details_filter;

        // this.items_model = response.filter_details.model;
        // this.items_transmission = response.filter_details.transmission;
        // this.locations = response.locations;
        this.booking_type = response.booking_type
        if (response.booking_type) {
          this.active = response.booking_type[0].name
        }
      });
    },
    prepare_car_details: function () {
      this.get_car_details().then((response) => {
        this.vehicleResult = response;
      });
    },
    prepare_text_setting: function() {
      this.get_text_setting().then((response) =>{
        this.title = response.title
        this.brand_select_text = response.brand_select_text
        this.appointment_select_text = response.appointment_select_text
      })
    },
    check_brand_enabled: function (brand) {
      if (typeof brand.enable !== "undefined") {
        if (brand.enable === 1) {
          return false;
        } else {
          return true;
        }
      }
    },
    check_slot_booked: function (slot) {
      if (slot.booked === 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.prepare_text_setting()
    this.prepare_car_type_model_details();
    this.prepare_car_details();
  },
  watch: {
    value_brand(newValue) {
      if(this.value_locations == null || this.value_locations == '') {
        console.log('yes')
      }
      // if(typeof this.value_locations != "string"){
        this.locations = []
          let brand_details = {
            brand: newValue.name
          };
        this.get_location_from_brand(brand_details).then((response) => {
          this.locations = response;
        });
      // }
      if (
        typeof this.value_brand == "object" &&
        this.value_locations != "" &&
        typeof this.value_locations == "string" &&
        typeof this.value_datePicker == "string"
      ) {
        let car_details = {
          brand: this.value_brand.name,
          location: this.value_locations,
          date: this.value_datePicker
        };

        this.get_available_slot_consultation(car_details).then((response) => {
          this.items_available_slots = response;
        });
      }
    },
    value_locations() {
      if(typeof this.value_brand != "object"){
        this.brand_details = []
          let location_details = {
            location: this.value_locations
          };
        this.get_brands_from_location(location_details).then((response) => {
          this.brand_details = response;
        });
      }
  
      if (
        typeof this.value_brand == "object" &&
        this.value_locations != "" &&
        typeof this.value_locations == "string" &&
        typeof this.value_datePicker == "string"
      ) {
        let car_details = {
          brand: this.value_brand.name,
          location: this.value_locations,
          date: this.value_datePicker
        };

        this.get_available_slot_consultation(car_details).then((response) => {
          this.items_available_slots = response;
        });
      }
    },
    value_datePicker(newValue) {
      this.value_selected_slot = "";
      this.items_available_slots = [];
      if (
        typeof this.value_brand == "object" &&
        typeof this.value_locations == "string"
      ) {
        // let booking_type_details = {
        //   date: this.value_datePicker,
        //   b_type: this.active
        // }
        // this.consulation_appointment_type_disable(booking_type_details).then((response) => {
        //   if (response) {
        //     if (response.warning_message != false){
        //       alert(response.warning_message);

        //     }
        //     this.online_disabled = response.online_disabled
        //     this.vor_ort_disabled = response.vor_ort_disabled
        //     this.active = response.active

        //   }
        // });
        let car_details = {
          brand: this.value_brand.name,
          location: this.value_locations,
          date: this.value_datePicker
        };

        this.get_available_slot_consultation(car_details).then((response) => {
          this.items_available_slots = response;
        });
        this.book_model_data.value_datePicker = newValue;
      } else {
        this.book_model_data.value_datePicker = null;
      }
    },
    value_selected_slot(newValue) {
      if (typeof this.value_selected_slot == "object") {
        this.book_model_data.value_selected_slot = newValue;
      } else {
        this.book_model_data.value_selected_slot = null;
      }
    },
    book_model_data: {
      handler() {
        if (
          // typeof this.value_type == "object" &&
          // typeof this.value_brand == "object" &&W
          typeof this.value_brand == "object" &&
          typeof this.value_locations == "string" &&
          // typeof this.value_model == "string" &&
          // typeof this.value_transmission == "string" &&
          // typeof this.value_type_of_fuel == "string" &&
          typeof this.value_selected_slot == "object"
        ) {
          this.$emit("allow-booking-form", false);
          var booking_data = {
            brand: this.value_brand.name,
            location: this.value_locations,
            booking_date: this.value_datePicker,
            from_time: this.value_selected_slot.from_time,
            to_time: this.value_selected_slot.to_time,
            personal_online: this.active
          };
          this.$emit("booking-data", booking_data);
        } else {
          this.$emit("allow-booking-form", true);
        }
      },
      deep: true,
    },
  },
  data: () => ({
    base_url: "https://demo-backend.testdrive-heroes.com/",
    car_type_model_details: "",
    value_type: "",
    value_brand: "",
    value_locations:"",
    value_vehicle: "",
    current_date: new Date().toISOString().substr(0, 10),
    value_datePicker: new Date().toISOString().substr(0, 10),
    value_model: null,
    value_transmission: null,
    value_type_of_fuel: null,
    value_selected_slot: "",
    vehicle_type_filter: "",
    brand_details_filter: "",
    vehicle_details: "",
    brand_details: null,
    vehicleResult: [],
    items_model: [],
    items_transmission: [],
    items_type_of_fuel: [],
    items_available_slots: [],
    book_model_data: {
      value_type: "",
      value_brand: "",
      value_vehicle: "",
      value_datePicker: new Date().toISOString().substr(0, 10),
      value_model: "",
      value_transmission: "",
      value_type_of_fuel: "",
      value_selected_slot: "",
    },
    booking_type: null,
    locations:null,
    active: 'Online',
    vor_ort_disabled: false,
    online_disabled: false,
    title:"",
    brand_select_text:"",
    appointment_select_text:""
  }),
};
</script>


<style>
.custom-datepicker .v-date-picker-table .v-btn {
  font-size: 16px;
}
.v-list-item__title{
  text-transform: uppercase;
}
.v-slide-group__content .v-card--link:before, .v-card .v-card--link:before{
  background: white;
}
.v-slide-group__content .theme--light.v-sheet--outlined , .v-card .theme--light.v-sheet--outlined{
    border-color: #1976d2;
    border-width: 2px;
}
.location-label{
  color: #1976d2;
}
.book-type .v-chip.v-size--large {
  border-radius:0
}
/* .location-type .v-chip.v-size--large { */
  /* border-radius:0; */
  /* border: 1px solid black;
  background: white */
/* } */
</style>