<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
         {{step1_text}}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        {{step2_text}}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3"> {{step3_text}} </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="mb-12" color=" lighten-1" flat>
          <BookTestDrive
            @allow-booking-form="allow_booking_form"
            @booking-data="get_booking_data"
          />
          <v-card class="d-flex justify-center" flat>
            <v-btn color="primary" :disabled="booking_form" @click="showBookingForm()">
              {{button1_text}}
            </v-btn>
          </v-card>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card class="mb-12" color=" lighten-1" flat>
          <BookingForm
            @booking-submit="submit_booking"
            @booking-back="e1 = 1"
            :set_booking_data="booking_data"
          />
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="d-flex justify-center flat" height="500"> 
          <v-card class="align-self-center" flat>
            <v-alert prominent type="success" color="primary" >
                Thank you. Your Reservation was a success. 
            </v-alert>
            <v-btn href="/" class="center">
              Book another consulting
            </v-btn>
          </v-card>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import BookTestDrive from "./BookTestDrive";
import BookingForm from "./BookingForm";
import { mapActions } from "vuex";


export default {
  name: "Stepper",
  components: {
    BookTestDrive,
    BookingForm,
  },
  methods: {
    ...mapActions([
      "get_text_setting"
    ]),
    allow_booking_form: function (e) {
      this.booking_form = e;
    },
    get_booking_data: function (data) {
      this.booking_data = data;
    },
    showBookingForm() {
      this.e1 = 2
      window.scrollTo(0,0);
    },
    prepare_text_setting: function() {
      this.get_text_setting().then((response) =>{
        this.step1_text = response.step_1_text
        this.step2_text = response.step_2_text
        this.step3_text = response.step_3_text
        this.button1_text = response.button_step_1_text
      })
    },
    submit_booking: function (result) {
      if (result) {
        this.e1 = 3;
      } else {
        alert("Bitte versuche es erneut");
      }
    },
  },
  created() {
    this.prepare_text_setting()
  },
  data() {
    return {
      e1: 1,
      booking_form: true,
      booking_data: null,
      step1_text:"",
      step2_text:"",
      step3_text:"",
      button1_text:"",
      privacy_text:"",
      privacy_url:"",
      imprint_text:"",
      imprint_url:""
    };
  },
};
</script>
